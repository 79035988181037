import React from "react"
import {Helmet} from "react-helmet"

import {Link, withPrefix, graphql} from "gatsby"
import Layout from "@layout/layout"
import SEO from "@layout/seo";

export default ({data}) => {
    const post = data.markdownRemark
    const categories = data.allFile.edges.map(({node}) => node.childMarkdownRemark.frontmatter);
    return (
        <Layout>
            <SEO
                title={post.frontmatter.metaTitle}
                description={post.frontmatter.metaDescription}
            />

            <Helmet
                meta={[
                    {
                        property: `og:title`,
                        content: post.frontmatter.title.trim()
                    },
                    {
                        property: `og:url`,
                        content: `https://sasafoundation.com/${post.frontmatter.slug}`
                    },
                ]}
            >
                <script type="application/ld+json">
                    {
                        `
          {"@context":"http:\\/\\/schema.org","@type":"WebSite","@id":"#website","url":"https:\\/\\/tiliamed.pl\\/","name":"Tiliamed"}
          `
                    }
                </script>
            </Helmet>
            <div className="content">
                <main>
                    <section
                        className="page-margin offer-single post-195 offer type-offer status-publish hentry type-prowadzenie">
                        <div className="offer-single__hero"
                             style={{backgroundImage: `url(${withPrefix(post.frontmatter.background_image)}`}}>
                        </div>
                        <div className="container-1075">
                            <div className="row">
                                <div className="col-sm-5 col-md-4">
                                    <div className="offer-single__menu--dropdown visible-xs">
                                        <div className="dropdown">
                                            <button className="btn dropdown-toggle" type="button" id="dropdownMenu1"
                                                    data-toggle="dropdown" aria-expanded={false}>
                                                <span>ROZWIŃ LISTĘ ZABIEGÓW</span>
                                                <svg width={11} height={20} viewBox="0 0 11 20"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <title>strzałka lewo</title>
                                                    <path
                                                        d="M3.316 9.622l7.299 7.298a1.375 1.375 0 0 1-1.944 1.944l-8.25-8.25c-.26-.25-.421-.6-.421-.99 0-.394.161-.745.42-.995L8.67.38c.25-.234.585-.38.955-.38a1.375 1.375 0 0 1 .895 2.418L3.316 9.622z"
                                                        fill="#9F9F9F" fillRule="evenodd"/>
                                                </svg>
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
                                                <li className="dropdown-header">Opieka nad ciężarną</li>
                                                {categories
                                                    .filter((category) => category.type === 'Opieka nad ciężarną')
                                                    .map((item, key) => (
                                                        <li key={key}>
                                                            <Link to={item.slug}
                                                                  className={item.slug === post.frontmatter.slug ? 'active' : ''}>
                                                                {item.title}
                                                            </Link>
                                                        </li>
                                                    ))}
                                                <li className="dropdown-header">Badania USG w ciąży</li>
                                                {categories
                                                    .filter((category) => category.type === 'Badaania USG w ciąży')
                                                    .map((item, key) => (
                                                        <li key={key}>
                                                            <Link to={item.slug}
                                                                  className={item.slug === post.frontmatter.slug ? 'active' : ''}>
                                                                {item.title}
                                                            </Link>
                                                        </li>
                                                    ))}
                                                <li className="dropdown-header">Ginekologia</li>
                                                {categories
                                                    .filter((category) => category.type === 'Ginekologia')
                                                    .map((item, key) => (
                                                        <li key={key}>
                                                            <Link to={item.slug}
                                                                  className={item.slug === post.frontmatter.slug ? 'active' : ''}>
                                                                {item.title}
                                                            </Link>
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                    </div>
                                  <div className="offer-single__menu hidden-xs">
                                    <p className="offer-single__menu__type">Opieka nad ciężarną</p>
                                    <ol>
                                      {categories
                                        .filter((category) => category.type === "Opieka nad ciężarną")
                                        .map((item, key) => (
                                          <li key={key}>
                                            <Link to={item.slug}
                                                  className={item.slug === post.frontmatter.slug ? "active" : ""}>
                                              {item.title}
                                            </Link>
                                          </li>
                                        ))}
                                    </ol>
                                    <p className="offer-single__menu__type">Badania USG w ciąży</p>
                                    <ol>
                                      {categories
                                        .filter((category) => category.type === "Badania USG w ciąży")
                                        .map((item, key) => (
                                          <li key={key}>
                                            <Link to={item.slug}
                                                  className={item.slug === post.frontmatter.slug ? "active" : ""}>
                                              {item.title}
                                            </Link>
                                          </li>
                                        ))}
                                    </ol>
                                    <p className="offer-single__menu__type">Ginekologia</p>
                                    <ol>
                                      {categories
                                        .filter((category) => category.type === "Ginekologia")
                                        .map((item, key) => (
                                          <li key={key}>
                                            <Link to={item.slug}
                                                  className={item.slug === post.frontmatter.slug ? "active" : ""}>
                                              {item.title}
                                            </Link>
                                          </li>
                                        ))}
                                    </ol>
                                    <p className="offer-single__menu__type">Dermatologia</p>
                                    <ol>
                                      {categories
                                        .filter((category) => category.type === "Dermatologia")
                                        .map((item, key) => (
                                          <li key={key}>
                                            <Link to={item.slug}
                                                  className={item.slug === post.frontmatter.slug ? "active" : ""}>
                                              {item.title}
                                            </Link>
                                          </li>
                                        ))}
                                    </ol>
                                    <p className="offer-single__menu__type">Medycyna estetyczna</p>
                                    <ol>
                                      {categories
                                        .filter((category) => category.type === "Medycyna estetyczna")
                                        .map((item, key) => (
                                          <li key={key}>
                                            <Link to={item.slug}
                                                  className={item.slug === post.frontmatter.slug ? "active" : ""}>
                                              {item.title}
                                            </Link>
                                          </li>
                                        ))}
                                    </ol>
                                  </div>
                                </div>
                              <div className="col-sm-7 col-md-8">
                                <div className="offer-single__content">
                                  <div className="breadcrumbs">
                      <span xmlnsv="http://rdf.data-vocabulary.org/#"><span typeof="v:Breadcrumb">
                          <a href="https://tiliamed.pl" rel="v:url"
                             property="v:title">Poradnia ginekologiczna Tiliamed</a> »
                           <span rel="v:child" typeof="v:Breadcrumb">
                            <span property="v:title"> Oferta </span> »
                            <span className="breadcrumb_last"> {post.frontmatter.title} </span>
                          </span>
                        </span>
                      </span>
                                  </div>
                                  <h1 className="offer-single__heading">
                                            {post.frontmatter.title}
                                        </h1>
                                        <div className="offer-single__text"
                                             dangerouslySetInnerHTML={{__html: post.html}}/>
                                        {post.frontmatter.price !== '' &&
                                            <div className="offer-single__price">
                                                <p className="key">Cena:</p>
                                                <p className="value">{post.frontmatter.price}</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query OfferQuery($slug: String!) {
        markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
                date
                slug
                author
                background_image
                type
                price
                metaDescription
                metaTitle
            }
        }
        allFile(filter: {sourceInstanceName: {eq: "offer"}}) {
            edges {
                node {
                    sourceInstanceName
                    childMarkdownRemark {
                        html
                        frontmatter {
                            title
                            slug
                            type
                        }
                    }
                }
            }
        }
    }
`
